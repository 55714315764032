import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import blockDefinitionFunction from '../../utils/blockDefinition';

const CongratsModal = ({
  onClose,
  blockTasksArr,
  block,
  // getTotalProgress,
}) => {
  const tasksCount = blockTasksArr.length;

  //это просто определение номера блока
  const blockIndex = blockDefinitionFunction(block);

  let bonusNumber = '';
  if (block === "efghijk8" || block === "xyz01ab71") {
    // это для марафон на 12 заданий блок первый - efghijk8 и чтобы люди видели бонус1
    // это для автопроверки на 6 заданий блок два - xyz01ab71 и чтобы люди видели бонус1
    bonusNumber = "bonus1"
  } else if (block === "fghijkl9" || block === "z01abcd73") {
    // это для марафон на 12 заданий блок второй - fghijkl9 и чтобы люди видели бонус2
    // это для автопроверки на 6 заданий блок четыре - z01abcd73 и чтобы люди видели бонус2
    bonusNumber = "bonus2";
  } else if (block === "ghijklm10" || block === "1abcdef75") {
    // это для марафон на 12 заданий блок третий - ghijklm10 и чтобы люди видели бонус3
    // это для автопроверки на 6 заданий блок шесть - 1abcdef75 и чтобы люди видели бонус3
    bonusNumber = "bonus3";
  }
  // блоки просто детской проверки один, три и пять бонусы не получают и видят просто поздравляшку

  return (
    <ModalWrapper
      onClose={() => {
        onClose();
        // getTotalProgress();
      }}
      hideCloseButton
    >
      <section className={s.wrapper}>
        {/* <img className={s.winnerCup} src={cup} alt="award" /> */}
        <WinnerCup className={s.winnerCup} width="118" />
        <h2 className={s.modalTitle}>ВІДМІННА РОБОТА!</h2>
        <Close
          className={s.close}
          onClick={() => {
            onClose();
            // getTotalProgress();
          }}
        />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            Ти успішно пройшов {blockIndex}-й блок завдань!
          </p>
          <p className={s.text}>Твої результати:</p>
          <p className={s.result}>
            <span className={s.countTasks}>{tasksCount}</span>
            правильних відповідей
          </p>

          {bonusNumber === "bonus1" && (
            <>
              <p className={s.text}>
                Ти отримуєш обов'язкове завдання: встановити та налаштувати
                редактор коду Visual Studio Code (VSC).
              </p>
              <div className={s.buttonContainer}>
                <a
                  // onClick={() => {
                  //   onClose();
                  // }}
                  href="https://www.youtube.com/watch?v=orSyi8ByLuk&list=PLViULGko0FdiRgpeqJrDq0AU1QcDsDXrV&index=2&t=0s"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.button}
                >
                  Встановити і налаштувати VSС
                </a>
              </div>
            </>
          )}

          {bonusNumber === "bonus2" && (
            <>
              <p className={s.text}>
                Ти отримуєш обов'язкове завдання: завантажити архів з кодом
                проєкту "Дошка бажань"{" "}
                <a
                  href="https://drive.google.com/file/d/1PBr_-t0JaI0AVzz2CmVGKDGc5LzFF5Sn/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  за цим посиланням,
                </a>{" "}
                розархівувати його, та запустити у редакторі коду Visual Studio
                Code (VSC).
              </p>
              <div className={s.buttonContainer}>
                <a
                  // onClick={() => {
                  //   onClose();
                  // }}
                  href="https://www.youtube.com/watch?v=98WZahQDtJo&feature=youtu.be"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.button}
                >
                  Запустити проєкт у VSC
                </a>
              </div>
            </>
          )}

          {bonusNumber === "bonus3" && (
            <>
              <p className={s.text}>
                Ти отримуєш обов'язкове завдання: у редакторі коду (Visual
                Studio Code) виправити код проєкту "Дошка мрій" (який ти отримав
                виконавши завдання другого блоку) і створити посилання на свій
                особистий проєкт.
              </p>
              <div className={s.buttonContainer}>
                <a
                  // onClick={() => {
                  //   onClose();
                  // }}
                  href="https://www.youtube.com/watch?v=cJidb9SMdA8&feature=youtu.be"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.button}
                >
                  Cтворити посилання на свій особистий проєкт
                </a>
              </div>
            </>
          )}
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
