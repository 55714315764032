export default function blockDefinition(block) {
  if (block === "efghijk8" || block === "wxyz01a70") {
    return 1;
  } else if (block === "fghijkl9" || block === "xyz01ab71") {
    return 2;
  } else if (block === "ghijklm10" || block === "yz01abc72") {
    return 3;
  } else if (block === "z01abcd73") {
    return 4;
  } else if (block === "01abcde74") {
    return 5;
  } else if (block === "1abcdef75") {
    return 6;
  } else {
    return 0;
  }
}
