import React, { Component } from "react";
import ResizableWrapper from "../../components/ResizableWrapper/ResizableWrapper";
import CustomCodeMirror from "../CustomCodeMirror/CustomCodeMirror";
import styles from "./CodeEditors.module.css";

const disableStyle = {
  zIndex: -10,
};

const enableStyle = {
  zIndex: 10,
};

class CodeEditors extends Component {
  state = {
    showHTML: true,
  };

  handleshowHTML = () => {
    this.setState({ showHTML: true });
  };
  handleshowCSS = () => {
    this.setState({ showHTML: false });
  };

  render() {
    const { showHTML } = this.state;
    const {
      valueHTML,
      valueCSS,
      valueJS,
      handleChangeHMTL,
      handleChangeCSS,
      handleChangeJS,
      metadata,
      notEditableHtmlBlocks,
      notEditableCssBlocks,
      htmlHlLines,
      cssHlLines,
      currentTaskId,
      isRefreshCurrentQuestion,
      htmlCursor,
      cssCursor,
      runJsCode,
      notEditableJsBlocks,
      codeHlLines,
      codeCursor,
      upValueJSInState,
      activityTimeMonitoringInCodeEditor,
    } = this.props;

    return (
      <div className={styles.code_container}>
        {metadata.needCode ? (
          <>
            {/* display option when js editor is need - start */}
            {metadata && !metadata.needHTML && !metadata.needCss ? (
              <div className={styles.box}>
                <h4 className={styles.codeTitle}>Редактор JavaScript:</h4>
                <button
                  id="run-js-code"
                  onClick={runJsCode}
                  className={valueJS ? styles.jsButton : styles.disableJsButton}
                >
                  Запустити
                </button>
                <div className={styles.containerCode} id="js_box">
                  <CustomCodeMirror
                    mode="javascript"
                    code={valueJS}
                    onChangeCode={handleChangeJS}
                    notEditableBlocks={notEditableJsBlocks}
                    hlLines={codeHlLines}
                    propertyReadOnly={metadata && metadata.disableCodeEditor}
                    currentTaskId={currentTaskId}
                    isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                    positionCursor={codeCursor}
                    upValueJSInState={upValueJSInState}
                    activityTimeMonitoringInCodeEditor={
                      activityTimeMonitoringInCodeEditor
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={styles.htmlOrCssWrapper}>
                  <ul className={styles.switchingList}>
                    {metadata.needHTML && (
                      <li
                        onClick={this.handleshowHTML}
                        className={
                          !showHTML
                            ? styles.switchingListItem
                            : styles.activeswitchingListItem
                        }
                      >
                        index.html:
                      </li>
                    )}
                    {metadata.needCss && (
                      <li
                        onClick={this.handleshowCSS}
                        className={
                          showHTML
                            ? styles.switchingListItem
                            : styles.activeswitchingListItem
                        }
                      >
                        styles.css:
                      </li>
                    )}
                  </ul>

                  {metadata.needHTML && (
                    <div
                      className={styles.containerHTML}
                      id="html_box"
                      style={showHTML ? enableStyle : disableStyle}
                    >
                      <CustomCodeMirror
                        mode="html"
                        code={valueHTML}
                        onChangeCode={handleChangeHMTL}
                        notEditableBlocks={notEditableHtmlBlocks}
                        hlLines={htmlHlLines}
                        propertyReadOnly={
                          metadata && metadata.disableHtmlEditor
                        }
                        currentTaskId={currentTaskId}
                        isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                        positionCursor={htmlCursor}
                        activityTimeMonitoringInCodeEditor={
                          activityTimeMonitoringInCodeEditor
                        }
                      />
                    </div>
                  )}

                  {metadata.needCss && (
                    <div
                      className={styles.containerCSS}
                      id="css_box"
                      style={!showHTML ? enableStyle : disableStyle}
                    >
                      <CustomCodeMirror
                        mode="css"
                        code={valueCSS}
                        onChangeCode={handleChangeCSS}
                        notEditableBlocks={notEditableCssBlocks}
                        hlLines={cssHlLines}
                        propertyReadOnly={metadata && metadata.disableCssEditor}
                        currentTaskId={currentTaskId}
                        isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                        positionCursor={cssCursor}
                        activityTimeMonitoringInCodeEditor={
                          activityTimeMonitoringInCodeEditor
                        }
                      />
                    </div>
                  )}
                </div>

                <ResizableWrapper>
                  <div className={styles.box}>
                    <h4 className={styles.codeTitle}>Редактор JavaScript:</h4>
                    <button
                      id="run-js-code"
                      onClick={runJsCode}
                      className={
                        valueJS ? styles.jsButton : styles.disableJsButton
                      }
                    >
                      Запустити
                    </button>
                    <div className={styles.containerCode} id="js_box">
                      <CustomCodeMirror
                        mode="javascript"
                        code={valueJS}
                        onChangeCode={handleChangeJS}
                        notEditableBlocks={notEditableJsBlocks}
                        hlLines={codeHlLines}
                        propertyReadOnly={
                          metadata && metadata.disableCodeEditor
                        }
                        currentTaskId={currentTaskId}
                        isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                        positionCursor={codeCursor}
                        upValueJSInState={upValueJSInState}
                        activityTimeMonitoringInCodeEditor={
                          activityTimeMonitoringInCodeEditor
                        }
                      />
                    </div>
                  </div>
                </ResizableWrapper>
              </>
            )}
            {/* display option when js editor is need - end */}
          </>
        ) : (
          <>
            {/* display option when js editor is not needed - start */}
            <div className={styles.box}>
              <h4
                className={
                  metadata && metadata.disableHtmlEditor === true
                    ? styles.disableCodeTitle
                    : styles.codeTitle
                }
              >
                index.html:
              </h4>
              <div id="html_box">
                <CustomCodeMirror
                  mode="html"
                  code={valueHTML}
                  onChangeCode={handleChangeHMTL}
                  notEditableBlocks={notEditableHtmlBlocks}
                  hlLines={htmlHlLines}
                  propertyReadOnly={metadata && metadata.disableHtmlEditor}
                  currentTaskId={currentTaskId}
                  isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                  positionCursor={htmlCursor}
                  activityTimeMonitoringInCodeEditor={
                    activityTimeMonitoringInCodeEditor
                  }
                />
              </div>
            </div>
            {metadata && metadata.needCss && (
              <ResizableWrapper>
                <div className={styles.boxCss}>
                  <h4
                    className={
                      metadata && metadata.disableCssEditor === true
                        ? styles.disableCodeTitle
                        : styles.codeTitle
                    }
                    style={{left: "9px"}}
                  >
                    styles.css:
                  </h4>
                  <div id="css_box">
                    <CustomCodeMirror
                      mode="css"
                      code={valueCSS}
                      onChangeCode={handleChangeCSS}
                      notEditableBlocks={notEditableCssBlocks}
                      hlLines={cssHlLines}
                      propertyReadOnly={metadata && metadata.disableCssEditor}
                      currentTaskId={currentTaskId}
                      isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                      positionCursor={cssCursor}
                      activityTimeMonitoringInCodeEditor={
                        activityTimeMonitoringInCodeEditor
                      }
                    />
                  </div>
                </div>
              </ResizableWrapper>
            )}
            {/* display option when js editor is not needed - end */}
          </>
        )}
      </div>
    );
  }
}

export default CodeEditors;
