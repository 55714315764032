import React from "react";
import s from "./ErrorStub.module.css";
import logo from "../../assets/images/logo/goiteens_logo.png";

const ErrorStub = () => {
  return (
    <div className={s.container}>
      <img className={s.logo} src={logo} alt="logo" />
      <p className={s.text}>Не валідне посилання.</p>
      <p className={s.text}>План дій:</p>
      <p className={s.text}>
        1. Поверніться до telegram'а і спробуйте відкрити посилання ще раз.
      </p>
      <p className={s.text}>
        2. Зверніться до адміністратора сайту за допомогою.
      </p>
    </div>
  );
};

export default ErrorStub;
