import React, { useState } from "react";
import styles from "./Header.module.css";
import Logo from "../../assets/images/logo/goiteens_logo.png";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow/right-arrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow/left-arrow.svg";
import SelectCurrentQuestion from "../SelectCurrentQuestion/SelectCurrentQuestion";
import blockDefinitionFunction from '../../utils/blockDefinition';

const Header = ({
  blockTasksArr,
  currentTaskId,
  getPrevTask,
  getNextTask,
  passed,
  block,
  headerTextLogoRef,
  headerTextQuestionRef,
  passedTasks,
  getSpecificTask,
}) => {
  const [isOpenSelect, setSelect] = useState(false);
  const openSelect = () => setSelect(true);
  const closeSelect = () => setTimeout(() => setSelect(false), 0);

  const blockQuestionQuantity = blockTasksArr.length;
  const numberCurrentQuestion = blockTasksArr.indexOf(currentTaskId) + 1;
  const showPrevButton =
    blockTasksArr.indexOf(currentTaskId) === 0 ? false : true;
  const showNextButton =
    blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1 || !passed
      ? false
      : true;

  const blockIndex = blockDefinitionFunction(block);
  
  return (
    <header className={styles.header_container}>
      <div className={styles.headerContent}>
        <h1 className={styles.logo_container}>
          <a
            href="https://goiteens.ua/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Logo} alt="logo" className={styles.img} />
          </a>
          <span className={styles.logo_title} ref={headerTextLogoRef}>
            Знайомство з HTML/CSS та JavaScript
          </span>
        </h1>

        <div className={styles.questionCounter_container} >
          {showPrevButton && (
            <button onClick={getPrevTask} className={styles.button}>
              <LeftArrow className={styles.arrow} />
            </button>
          )}

          <div className={styles.count} ref={headerTextQuestionRef} onClick={openSelect}>
            Задача {numberCurrentQuestion} / {blockQuestionQuantity}

            {isOpenSelect && (
              <SelectCurrentQuestion
                closeSelect={closeSelect}
                passedTasks={passedTasks}
                getSpecificTask={getSpecificTask}
                blockTasksArr={blockTasksArr}
              />
            )}
          </div>

          {showNextButton && (
            <button onClick={getNextTask} className={styles.button}>
              <RightArrow className={styles.arrow} />
            </button>
          )}
        </div>

        <p className={styles.days_container}>
          Блок:&ensp;<span>{blockIndex}</span>&ensp;з&ensp;3
        </p>
      </div>
    </header>
  );
};

export default Header;
